import React from "react";

function PrivacyPolicy() {
    return (
        <div className="tnc-container">
            <h1>Privacy Policy for Funtcy</h1>
            <h2><strong>Last updated: 05 Apr 2024</strong></h2>
            <p>Welcome to Funtcy, operated by CricKshetra Entertainment Pvt Ltd ("Funtcy", "we", "us", or "our"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application and website (collectively, the "Service"). By using the Service, you consent to the collection and use of information in accordance with this policy.</p>
            <h2>Information Collection</h2>
            <p>We collect information to provide and improve our Service. This includes:</p>
            <h3>Information You Provide</h3>
            <ul><li><strong>Account Registration</strong>: We collect information via social login options, including, but not limited to, your name, email address, profile picture, and public profile information as permitted by your social network's privacy settings.</li><li><strong>Content Creation</strong>: Information you provide through the Service, such as text, images, and messages.</li></ul>
            <h3>Automatically Collected Information</h3>
            <ul><li><strong>Log Data</strong>: Details of your visits to our Service, including traffic data, logs, and other communication data and the resources that you access and use on the Service.</li><li><strong>Device Information</strong>: We collect information about the device you use to access our Service, including the device's unique device identifier, operating system, browser type, and mobile network information.</li></ul>
            <h3>Tracking Technologies and Cookies</h3>
            <ul><li><strong>Cookies</strong>: We use cookies and similar tracking technologies to track activity on our Service and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier.</li></ul>
            <h2>Use of Data</h2>
            <p>Funtcy uses collected data for various purposes:</p>
            <ul><li>To provide and maintain our Service</li><li>To notify you about changes to our Service</li><li>To allow you to participate in interactive features of our Service when you choose to do so</li><li>To provide customer support</li><li>To gather analysis or valuable information so that we can improve our Service</li><li>To monitor the usage of our Service</li><li>To detect, prevent, and address technical issues</li></ul>
            <h2>Disclosure of Data</h2>
            <ul><li><strong>Legal Requirements</strong>: Funtcy may disclose your Data in the belief that such action is necessary to:<ul><li>Comply with a legal obligation</li><li>Protect and defend the rights or property of Funtcy</li><li>Prevent or investigate possible wrongdoing in connection with the Service</li><li>Protect the personal safety of users of the Service or the public</li><li>Protect against legal liability</li></ul></li></ul>
            <h2>Security of Data</h2>
            <p>The security of your data is important to us. We strive to use commercially acceptable means to protect your Personal Data, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure.</p>
            <h2>Service Providers</h2>
            <p>We may employ third-party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
            <h2>Links to Other Sites</h2>
            <p>Our Service may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
            <h2>Changes to This Privacy Policy</h2>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us by email at <a href="_new">support@funtcy.app</a>.</p>
        </div >
    );
}

export default PrivacyPolicy;
