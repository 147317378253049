import React from "react";

function TermsAndConditions() {
    return (
        <div className="tnc-container">
            <h1>Terms and Conditions for Funtcy</h1>
            <h2><strong>Last updated: 05 Apr 2024</strong></h2>
            <h2>1. Introduction</h2>
            <p>
                Welcome to Funtcy, a fantasy cricket app ("Service") operated by CricKshetra Entertainment Pvt Ltd ("us", "we", or "our"). Funtcy is designed to provide a fun, competitive, and social platform for fantasy cricket exclusively among friends or within corporate groups. By accessing or using our Service, you agree to be bound by these Terms and Conditions ("Terms"). If you disagree with any part of the terms, then you do not have permission to access the Service.
            </p>
            <h2>2. Service Overview</h2>

            <p>Funtcy allows users to create private cricket fantasy leagues ("Leagues") where they can invite friends or corporate group members to participate in fantasy cricket contests. The Service is intended for entertainment purposes only and does not support, facilitate, or endorse any form of gambling or betting.</p>

            <h2>3. User Eligibility</h2>

            <p>The Service is intended solely for users who are 18 years of age or older.By using Funtcy, you represent and warrant that you meet this age requirement.</p >

            < h2 > 4. Account Registration</h2 >

            <p>To use Funtcy, you will be required to register an account through one of the available social login options provided within the Service. By choosing to register and use a social login, you authorize us to access and use certain information from your social network account as permitted under the terms of the social network. You are responsible for maintaining the confidentiality of your login credentials you use to sign up for Funtcy, and you are solely responsible for all activities that occur under those credentials. If you believe that your account has been compromised at any time, please notify your social network's support team and us.</p>

            <h2>5. Usage Guidelines</h2>

            <p>5.1 Funtcy is to be used for personal, non-commercial purposes within a private setting among known friends or corporate groups.<br />
                5.2 The Service is designed for entertainment purposes only and must not be used for any form of gambling or betting.<br />
                5.3 Users are prohibited from using the Service to engage in any illegal, fraudulent, or inappropriate activities.<br />
                5.4 Users agree to not share or misuse any personal information obtained from social logins or within the Service.<br />
                5.5 Funtcy reserves the right to suspend or terminate accounts found in violation of these Terms, particularly those engaged in unauthorized use of social login information.</p >

            < h2 > 6. Fees and Payments</h2>

            <p>6.1 Registration and basic access to Funtcy are free of charge.However, certain premium features, including but not limited to the creation of private Leagues and contest entries, may require the use of in-app currency.<br />
                6.2 In - app currency can be acquired through in-app purchases within the Service.The cost for in-app currency and its equivalent value in real currency will be clearly displayed within the app at the point of transaction.By completing an in-app purchase, users agree to pay the specified amount, which will be charged to their app store account associated with the purchase.<br />
                6.3 The in-app currency is utilized within Funtcy for accessing premium features, contest creation, and other services that may be introduced from time to time.The amount required for these services will be transparently communicated to the user prior to any transaction.<br />
                6.4 All purchases of in-app currency are considered final and are non - refundable except where required by law or as outlined in our Refund Policy.The in-app currency holds no monetary value outside of the Funtcy Service and cannot be exchanged for real money, goods, or other items with monetary value.<br />
                6.5 We reserve the right to modify the pricing of in-app currency, introduce new in-app purchases, or eliminate existing in-app purchases at our discretion and without prior notice to the users.<br />
                6.6 Transactions processed within Funtcy, including in-app purchases, are subject to our payment terms and conditions, as well as the terms and conditions of the app store through which the purchases are made.</p>

            < h2 > 7. Intellectual Property</h2 >

            <p>The Service and its original content, features, and functionality are and will remain the exclusive property of CricKshetra Entertainment Pvt Ltd and its licensors.</p>

            < h2 > 8. Prohibited Conduct</h2 >

            <p>Promoting, conducting, or participating in any form of gambling or betting through the Service is strictly prohibited. Funtcy is intended to be a fun and social platform for fantasy cricket among friends or corporate groups without any monetary stakes.</p>

            < h2 > 9. Termination</h2 >

            <p>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms.</p>

            < h2 > 10. Limitation of Liability</h2 >

            <p >CricKshetra Entertainment Pvt Ltd shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your access to or use of, or inability to access or use, the Service.</p >

            < h2 > 11. Changes to Terms</h2 >

            <p > We reserve the right, at our sole discretion, to modify or replace these Terms at any time.By continuing to access or use our Service after revisions become effective, you agree to be bound by the revised terms.</p >

            < h2 > 12. Disclaimer</h2 >

            <p > Funtcy, provided by CricKshetra Entertainment Pvt Ltd, is available on an "as is" and "as available" basis.We strive to ensure the Service is accessible and functioning properly, however, CricKshetra Entertainment Pvt Ltd makes no representations or warranties of any kind, express or implied, as to the operation of the Service, the accuracy or completeness of the Service's content, or the information, materials, or products included on the Service.</p>

            <p > CricKshetra Entertainment Pvt Ltd expressly disclaims all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose. CricKshetra Entertainment Pvt Ltd will not be liable for any damages of any kind arising from the use of the Service.</p >

            < h2 > 13. Miscellaneous</h2 >

            <p > 13.1 These Terms constitute the entire agreement between you and CricKshetra Entertainment Pvt Ltd regarding your use of the Service and supersede any prior agreements between you and CricKshetra Entertainment Pvt Ltd relating to your use of the Service.<br />
                13.2 The failure of CricKshetra Entertainment Pvt Ltd to enforce any right or provision of these Terms will not be considered a waiver of those rights.If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.</p >

            < h2 > 14. Contact Us</h2 >

            <p > For any questions about these Terms, please contact us at <a href="_new">support@funtcy.app</a>.</p >
        </div >
    );
}

export default TermsAndConditions;
