// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer>
            <Link to="/terms-and-conditions">Terms and Conditions</Link>&nbsp;
            <Link to="/privacy-policy">Privacy Policy</Link>&nbsp;
            <Link to="/support">Support</Link>
        </footer>
    );
}

export default Footer;
