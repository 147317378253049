import React, { useState } from 'react';

function ContactForm() {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleEmailChange = (event) => setEmail(event.target.value);
    const handleUsernameChange = (event) => setUsername(event.target.value);

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
    };

    return (
        <div style={styles.container}>
            {submitted ? (
                <p style={styles.message}>Thank you for your submission, our team will get in touch with you.</p>
            ) : (
                <form style={styles.form} onSubmit={handleSubmit}>
                    <div style={styles.formGroup}>
                        <label style={styles.label} htmlFor="email">Email:</label>
                        <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            style={styles.input}
                            required
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label} htmlFor="username">Username:</label>
                        <input
                            id="username"
                            type="text"
                            value={username}
                            onChange={handleUsernameChange}
                            style={styles.input}
                            required
                        />
                    </div>
                    <button type="submit" style={styles.button}>Submit</button>
                </form>
            )}
        </div>
    );
}

// Styles
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start', // Adjusted for better vertical spacing
        alignItems: 'center',
        paddingTop: '20px', // Add some padding at the top
        paddingBottom: '60px', // Add more padding at the bottom to ensure space for the footer
        minHeight: 'calc(100vh - 60px)', // Adjust minHeight to account for footer height
        textAlign: 'center',
        overflow: 'auto', // Ensure the container can scroll
    },
    form: {
        width: '80%',
        maxWidth: '500px',
        margin: '0 auto',
        padding: '20px',
        boxSizing: 'border-box',
    },
    formGroup: {
        marginBottom: '20px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold',
        fontSize: '16px', // Ensure label and button text sizes are consistent
    },
    input: {
        width: '100%',
        padding: '10px',
        fontSize: '16px', // Standardizing font size
        borderRadius: '5px',
        border: '1px solid #ccc',
    },
    button: {
        width: '100%',
        padding: '12px',
        fontSize: '16px', // Standardizing font size
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginTop: '20px',
    },
    message: {
        fontSize: '20px',
    }
};

export default ContactForm;
