import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Preloader from "./components/Preloader/Preloader";
import Timer from "./components/Countdown/Timer";
import Optin from "./components/Optin/Optin";
import Footer from './footer'; // Verify this import
import TermsAndConditions from './termsAndconditions'; // Verify this import
import PrivacyPolicy from './privacy_policy'; // Verify this import
import SupportPage from './support';
import DeleteAccount from './components/UserInput/delete_account'; // Verify this import

import "./styles.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/request-account-deletion" element={<DeleteAccount />} />
          <Route path="/" element={
            <div className="container">
              <h1>Website Coming Soon</h1>
              <Timer />
              <Optin />
              <Preloader />
            </div>
          } />
        </Routes>
        <Footer /> { }
      </div>
    </Router>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
