// In delete_account.js
import React from 'react';
import ContactForm from './contact_form'; // Ensure the path is correct based on your project structure

function DeleteAccount() {
    return (
        <div>
            <h1>Request Account Deletion</h1>
            <ContactForm />
        </div>
    );
}

export default DeleteAccount;
