import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faGamepad, faLock, faCreditCard, faQuestionCircle, faComments } from '@fortawesome/free-solid-svg-icons';
import './support_page.css';

const supportTopics = [
    { icon: faUserCircle, title: 'Getting Started with Funtcy', description: 'Create your account, join a league, understand fantasy cricket.' },
    { icon: faGamepad, title: 'Gameplay Guides', description: 'Creating and managing your teams, league management, understanding the scoring system.' },
    { icon: faLock, title: 'Account Management', description: 'Profile customization, account security, password reset and recovery.' },
    { icon: faCreditCard, title: 'Financial Transactions', description: 'In-app purchases, refund policy, secure payments.' },
    { icon: faQuestionCircle, title: 'Technical Support', description: 'App troubleshooting, device compatibility, connectivity issues.' },
    { icon: faComments, title: 'Feedback and Community', description: 'Submitting feedback, community guidelines.' },
];

const renderSupportTopics = () => {
    return supportTopics.map((topic, index) => (
        <div key={index} className="support-topic">
            <FontAwesomeIcon icon={topic.icon} size="2x" className="topic-icon" />
            <h3>{topic.title}</h3>
            <p>{topic.description}</p>
        </div>
    ));
};

function SupportPage() {
    return (
        <div>
            <h1>Support and Help Center</h1>
            <div className="support-container">
                {renderSupportTopics()}
            </div>
            < h2 > Contact Us</h2 >

            <p > For any questions about these topics, please contact us at <a href="_new">support@funtcy.app</a>.</p >
        </div>
    );
};

export default SupportPage;